import 'styles/index.scss'

/*
  DYNAMIC METABALLS
*/

function getRandomInRange(min, max) {
  return (Math.random() * (max - min + 1) + min).toFixed(2);
}

function generateRandomUuid() {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  const charactersLength = characters.length;
  for (let i = 0; i < 6; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

const metaStyleSheet = document.createElement("style");
document.head.appendChild(metaStyleSheet);

document.querySelectorAll(".meta-group .meta").forEach((meta) => {
  const uuid = generateRandomUuid();
  const uniqueAnimationName = `meta-move-${uuid}`;

  const keyframes = [];

  Array.from([0, 15, 30, 40, 60, 80, 100]).forEach((pos) => {
    keyframes.push({
      pos: pos,
      translateX: getRandomInRange(-35, 35),
      translateY: getRandomInRange(-3, 3),
      scaleX: getRandomInRange(0.75, 1.15),
      scaleY: getRandomInRange(0.95, 1.05),
    });
  });

  const keyframeRules = keyframes.map(
    (keyframe) =>
      `${keyframe.pos}% { transform: translate(${keyframe.translateX}vw, ${keyframe.translateY}%) scale(${keyframe.scaleX}, ${keyframe.scaleY}); }`
  );

  const styleSheetRule = `
    @keyframes ${uniqueAnimationName} {
      ${keyframeRules.join("\n")}
    }
  `;

  metaStyleSheet.sheet.insertRule(styleSheetRule);

  console.group(uniqueAnimationName);
  console.log(keyframeRules.join("\n"));
  console.groupEnd();

  const duration = `${getRandomInRange(15, 40)}s`;
  const delay = `${getRandomInRange(-15, -5)}s`;

  meta.style.setProperty("--animation-name", uniqueAnimationName);
  meta.style.setProperty("--animation-duration", duration);
  meta.style.setProperty("--animation-delay", delay);
});